import * as React from "react";
import { graphql, Link } from "gatsby";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAmbulance,
    faCalendarAlt,
    faChevronRight,
    faMoneyBill,
    faPlusSquare,
    faSignal,
    faTooth,
    faUniversity,
    faWheelchair
} from "@fortawesome/free-solid-svg-icons";

import BenefitsBlock from "../../components/careers/benefitsBlock";
import ContentSection from "../../components/contentSection";
import Layout from "../../components/layout";
import PageTitle from "../../components/pageTitle";
import SEO from "../../components/seo";
import careers from "../../images/careers.jpg";
import { Benefit } from "../../models";

interface CareersState {
    benefits: Benefit[];
}

interface YamlNode {
    node: {
        path: string;
        title: string;
        locations?: string[];
        current: boolean;
    };
}

interface CareersProps {
    data: {
        allJobPostingsYaml: {
            edges: YamlNode[];
        };
    };
}

class Careers extends React.Component<CareersProps, CareersState> {
    state: CareersState = {
        benefits: [
            {
                icon: faCalendarAlt,
                text:
                    "PTO of 24 days during the first 5 years of employment, 30 days between 5 and 10 years of employment, and 36 days after 10 years employment. Depending upon the needs of the business, flexible work arrangements and time off without pay are important optional aspects of the Tiber Creek work environment."
            },
            {
                icon: faAmbulance,
                text:
                    "Medical insurance is provided by EVHC. Tiber Creek contributes to reduce the employee cost of insurance. There is no insurance monthly payment for employee only coverage, additionally there is no deductible on the medical plan. Medical coverage commences on date of hire (DOH)."
            },
            {
                icon: faTooth,
                text:
                    "Dental insurance is administered by EVHC on Cigna network. Vision insurance is administered by Eyemed Vision Plan. Coverage commences on date of hire (DOH)."
            },
            {
                icon: faMoneyBill,
                text:
                    "Flexible Spending Arrangement (FSA) is available for pre-tax, un-reimbursed medical, dental, vision, and dependent care expenses. The FSA maximum per year is $3,300, DCA is $5,000."
            },
            {
                icon: faWheelchair,
                text:
                    "Short and long term disability insurance, accidental death and dismemberment, as well as term life insurance is provided by SunLife. All of these benefits are paid entirely by Tiber Creek. Coverage commences after the first two months of full-time employment."
            },
            {
                icon: faSignal,
                text:
                    "401(k) plan is provided by Fidelity Investments. Plan available after the first two months of employment. Tiber Creek makes a matching contribution of fifty cents for every dollar contributed by the employee, up to a maximum company contribution of 4% per pay period."
            },
            {
                icon: faPlusSquare,
                text:
                    "Performance Bonus is an important optional component of the overall compensation environment for Tiber Creek."
            },
            {
                icon: faUniversity,
                text:
                    "Continuing Academic Education and Professional Development Training and Certification reimbursement, as well as a referral bonus plan are available."
            }
        ]
    };

    render() {
        const { benefits } = this.state;
        const {
            data: {
                allJobPostingsYaml: { edges }
            }
        } = this.props;

        const currentPostings = edges.filter(x => x.node.current);
        const prevPostings = edges.filter(x => !x.node.current);

        return (
            <Layout>
                <SEO title="Careers" />

                <PageTitle title="Careers" imageUrl={careers} />

                <ContentSection>
                    <p>
                        We are committed to the satisfaction and happiness of
                        our employees; our most valuable assets. In addition to
                        top industry pay, we offer generous benefits, ample
                        vacation/leave time, training/skills building
                        opportunities and a great work environment. We will do
                        everything possible to ensure the satisfaction of our
                        employees. We are a progressive organization that
                        guarantees equal opportunity and fair treatment,
                        regardless of race, gender, religion, national origin or
                        sexual orientation. Tiber Creek Consulting provides and
                        maintains a Drug Free Workplace.
                    </p>

                    <p>
                        Interested in joining our team? Please scroll down to
                        the bottom of the page to view and{" "}
                        <Link to="/careers/apply">apply now</Link> to a current
                        opening or to be considered for a soon-to-be posted
                        position.
                    </p>
                </ContentSection>

                <ContentSection
                    title="Our Benefits"
                    backgroundColor="dark"
                    textColor="white"
                >
                    <Row>
                        {benefits.map((benefit, i) => (
                            <Col
                                lg={6}
                                xl={3}
                                key={`benefit-${i}`}
                                className="my-3"
                            >
                                <BenefitsBlock benefit={benefit} />
                            </Col>
                        ))}
                    </Row>
                </ContentSection>

                <ContentSection title="Job Openings">
                    <Row>
                        <Col>
                            <p>
                            We currently have remote and on-site openings 
                            for .NET and Full-Stack Software Developers, 
                            Software Solution Project Managers, Quality 
                            Assurance (QA) Test Engineers, Proposal Team, 
                            Technical Writers and Analysts (Business/Functional, 
                            Military, Data, Database). Applicants must be clearable 
                            for a DoD Secret clearance. Some positions require 
                            clearance to start.
                            </p>

                            <p className="mb-5">
                                Click below to view current and past openings
                                and apply.
                            </p>

                            <div className="text-center">
                                <Link
                                    to="/careers/openings"
                                    className="btn btn-lg btn-primary"
                                >
                                    View Job Openings
                                    <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="ml-3"
                                    />
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </ContentSection>

                <div className="mb-5">
                    <ContentSection
                        backgroundColor="secondary"
                        textColor="light"
                        title="Interested in Joining Our Team?"
                    >
                        <p className="mb-4">
                            We appreciate receiving resumes from well qualified
                            candidates for current and potential openings, so
                            please take a few moments to tell us about yourself
                            and provide a resume.
                        </p>

                        <div className="text-center">
                            <Link to="/careers/apply" className="btn btn-dark">
                                Apply Now
                                <FontAwesomeIcon
                                    icon={faChevronRight}
                                    className="ml-2"
                                />
                            </Link>
                        </div>
                    </ContentSection>
                </div>
            </Layout>
        );
    }
}

export default Careers;

export const pageQuery = graphql`
    {
        allJobPostingsYaml(sort: { fields: title }) {
            edges {
                node {
                    path
                    title
                    locations
                    current
                }
            }
        }
    }
`;
