import * as React from "react";
import { Card, CardBody, CardText } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Benefit } from "../../models";

interface BenefitsBlockProps {
    benefit: Benefit;
}

const BenefitsBlock: React.FunctionComponent<BenefitsBlockProps> = ({
    benefit: { icon, text }
}) => (
    <Card color="secondary" className="h-100">
        <CardBody className="h-100">
            <div className="d-flex flex-column justify-content-start align-items-center h-100">
                <div className="mb-4">
                    {icon && <FontAwesomeIcon icon={icon} size="3x" />}
                </div>

                <CardText>{text}</CardText>
            </div>
        </CardBody>
    </Card>
);

export default BenefitsBlock;
